













import { Component, Ref } from 'vue-property-decorator';
import { BaseComponent } from '@common-src/mixins/base-component';
import { ProjectEntityModel } from '@common-src/entity-model/project-entity';
import ProjectService from '@common-src/service/project';
import { UserStoreModule } from '@common-src/store/modules/user';
import FormComponent from '@common-src/mixins/form-component';
import { ViewModeType } from '@common-src/model/enum';
import { FormControlType } from '@common-src/model/form-control';

@Component
export default class ConfigImageMobileComponent extends BaseComponent {
    /**
     * 表单组件
     */
    @Ref(JTL.CONSTANT.DEFAULT_FORM_NAME)
    jtlForm: FormComponent<any>;

    mounted() {
        if (!_.get(UserStoreModule.CurrentProject, 'id')) {
            this.showMessageWarning('没有选择项目');
            return;
        }
        const wxLoginBgValue = _.get(UserStoreModule.CurrentProject, 'wxLoginBg');
        const wxBanner = _.get(UserStoreModule.CurrentProject, 'wxBanner');
        const controlList = [
            {
                key: 'wxLoginBg',
                label: '登录页面',
                value: wxLoginBgValue ? [{ url: wxLoginBgValue, type: 'image' }] : [],
                type: FormControlType.UPLOAD,
                listType: 'picture-card',
                showUploadList: true,
                maxFileNumber: 1,
                message: '建议上传尺寸1334 * 750',
                noMessageTooltip: true,
                layout: { labelCol: 5, wrapperCol: 12, width: '100% ' },
                accept: '.jpg,.png,.gif'
            },
            {
                key: 'wxBanner',
                label: '首页Banner',
                value: wxBanner ? [{ url: wxBanner, type: 'image' }] : [],
                type: FormControlType.UPLOAD,
                listType: 'picture-card',
                showUploadList: true,
                maxFileNumber: 1,
                message: '建议上传尺寸500 * 750',
                noMessageTooltip: true,
                layout: { labelCol: 5, wrapperCol: 12, width: '100% ' },
                accept: '.jpg,.png,.gif'
            }
        ];
        this.jtlForm.initFormByControls(controlList, ViewModeType.UPDATE);
    }
    saveClick() {
        const projectId = _.get(UserStoreModule.CurrentProject, 'id');
        const mobileInfo = {
            wxLoginBg: _.get(this.jtlForm.formModel.wxLoginBg, '[0].url'),
            wxBanner: _.get(this.jtlForm.formModel.wxBanner, '[0].url')
        };
        return ProjectService.saveWxInfo(projectId, mobileInfo).then(res => {
            this.showMessage('保存成功！', 'success');
        }).catch(err => {
            throw err;
        });
    }
}

